
export default {
  name: "Header",
  data() {
    return {
      userInfo:null
    };
  },
  computed: {
    user() {
      return this.$store.getters['user/user'];
    }
  },
  watch: {
    user(newUser) {
      if (newUser) {
        this.userInfo = newUser;
      }else{
        this.userInfo = null;
      }
    }
  },
  methods: {
    loadUserInfo() {
      if (process.client) {
        const userInfo = JSON.parse(localStorage.getItem('user'));
        if (userInfo) {
          this.$store.dispatch('user/login', userInfo);
        }else{
            this.userInfo = null;
        }
      }
    }
  },
  mounted() {
    if (!this.user) {
      this.loadUserInfo();
    }else{
        this.userInfo = this.user;
    }
  }
};
