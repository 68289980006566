// store/user.js
export const state = () => ({
    user: null
  });
  
  export const mutations = {
    setUser(state, user) {
      state.user = user;
      if (process.client) {
        localStorage.setItem('user', JSON.stringify(user));
      }
    },
    clearUser(state) {
      state.user = null;
      if (process.client) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
      }
    }
  };
  
  export const actions = {
    login({ commit }, user) {
      commit('setUser', user);
    },
    initUser({ commit }) {
      if (process.client) {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
          commit('setUser', user);
        }
      }
    },
    logout({ commit }) {
      commit('clearUser');
    }
  };
  
  export const getters = {
    user: state => state.user
  };
  