// plugins/toast.js
import Vue from 'vue';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

if (process.client) {
  Vue.use(Toast, {
    // 可选配置项
    position: 'top-right',
    timeout: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    showCloseButtonOnHover: true,
    hideProgressBar: false,
    closeButton: 'button',
    icon: true,
  });
}
