export const Footer = () => import('../..\\components\\Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../..\\components\\Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const IndexAsk = () => import('../..\\components\\index\\Ask.vue' /* webpackChunkName: "components/index-ask" */).then(c => wrapFunctional(c.default || c))
export const IndexConnected = () => import('../..\\components\\index\\Connected.vue' /* webpackChunkName: "components/index-connected" */).then(c => wrapFunctional(c.default || c))
export const IndexHello = () => import('../..\\components\\index\\Hello.vue' /* webpackChunkName: "components/index-hello" */).then(c => wrapFunctional(c.default || c))
export const IndexInfos = () => import('../..\\components\\index\\Infos.vue' /* webpackChunkName: "components/index-infos" */).then(c => wrapFunctional(c.default || c))
export const IndexInfosTwo = () => import('../..\\components\\index\\InfosTwo.vue' /* webpackChunkName: "components/index-infos-two" */).then(c => wrapFunctional(c.default || c))
export const IndexMarque = () => import('../..\\components\\index\\Marque.vue' /* webpackChunkName: "components/index-marque" */).then(c => wrapFunctional(c.default || c))
export const PriceBuy = () => import('../..\\components\\price\\Buy.vue' /* webpackChunkName: "components/price-buy" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
