// plugins/axios.js
import Vue from 'vue';

export default function({ $axios, redirect }) {
  $axios.onRequest(config => {
    if (process.client) {
      const user = JSON.parse(localStorage.getItem('user'));
      const token = user ? user.token : null;
      if (token) {
        config.headers.common['itoken'] = token;
      }
    }
    return config;
  });

  $axios.onResponse(response => {
    if (process.client) {
      const data = response.data;

      if (data && data.code === "401") {
        console.log(Vue.prototype.$toast);

        // 未登录，跳转到登录界面并显示错误信息
        Vue.prototype.$toast.error('未登录，请重新登录', {
          position: 'top-right',
          timeout: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          showCloseButtonOnHover: true,
          hideProgressBar: false,
          closeButton: 'button',
          icon: true,
        });
        redirect('/login');
      } else if (data && data.success === false) {
        // 请求失败，显示错误信息
        Vue.prototype.$toast.error(data.message, {
          position: 'top-right',
          timeout: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          showCloseButtonOnHover: true,
          hideProgressBar: false,
          closeButton: 'button',
          icon: true,
        });
        return Promise.reject(new Error(data.message));
      }
    }
    return response;
  });

  $axios.onError(error => {
    if (process.client) {
      console.log('Error object:', error);
      const response = error.response ? error.response : null;
      console.log('Error response:', response);

      const message = response && response.data && response.data.message
        ? response.data.message
        : '发生错误，请稍后重试';

      Vue.prototype.$toast.error(message, {
        position: 'top-right',
        timeout: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        showCloseButtonOnHover: true,
        hideProgressBar: false,
        closeButton: 'button',
        icon: true,
      });
    }
    return Promise.reject(error);
  });
}
